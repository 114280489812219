<script setup lang="ts">
import type { LabelWithLang, SettingUIHelpType } from '@gem/control-v2';
import { GButton, GPopover, GInput } from '@gem/uikit-v2';
import { SettingTitle } from '@gem/control-v2';
import { cn, ID } from '@gem/common';
import type { IconName } from '@gem/icons';
import { computed, ref, watch } from 'vue';
import useSettingSideBarStore from '../../hooks/useSettingSideBarStore';
import None from '@gem/uikit/src/assets/icons/none.vue';
type ComboSettingProps = {
  id?: string;
  isFullWidth?: boolean;
  label?: LabelWithLang;
  comboIcon?: IconName;
  comboColor?: string;
  comboImage?: string;
  contentDisplay?: string;
  help?: SettingUIHelpType;
  showLabel?: boolean;
  placeholder?: string;
  isParent?: boolean;
  isChild?: boolean;
  isHideClear?: boolean;
  inputStyle?: string;
};

const reference = ref<Element>();
const popoverReference = ref();
const isOpenMoreSetting = ref(false);

const props = withDefaults(defineProps<ComboSettingProps>(), {
  showLabel: true,
});

const emit = defineEmits<{
  (e: 'clear'): void;
  (e: 'click'): void;
}>();

const sideBarStore = useSettingSideBarStore();

const comboID = computed(() => `${props.id}-${ID()}`);
const isClosePopover = computed(
  () => sideBarStore.comboParentActiveID === comboID.value && sideBarStore.isCloseComboParentActive,
);

const handleClear = () => {
  popoverReference.value.close();
  emit('clear');
};

const handleOpenPopover = () => {
  isOpenMoreSetting.value = true;
  emit('click');
  if (props.isParent) {
    sideBarStore.setComboParentActiveID(comboID.value);
    sideBarStore.setComboParentRef(reference);
    sideBarStore.setIsCloseComboParentActive(false);
  } else if (props.isChild) {
    sideBarStore.setIsCloseComboParentActive(true);
    sideBarStore.setIsOpenComboChildren(true);
    popoverReference.value.updatePosition(sideBarStore.comboParentRef);
  } else {
    sideBarStore.setComboParentActiveID(undefined);
    sideBarStore.setComboParentRef(undefined);
    sideBarStore.setIsCloseComboParentActive(true);
  }
};

const handleClosePopover = () => {
  isOpenMoreSetting.value = false;
  if (props.isChild) {
    sideBarStore.setIsCloseComboParentActive(false);
    sideBarStore.setIsOpenComboChildren(false);
  } else if (props.isParent && sideBarStore.isOpenComboChildren) {
    sideBarStore.setIsCloseComboParentActive(true);
  } else {
    sideBarStore.setComboParentActiveID(undefined);
    sideBarStore.setComboParentRef(undefined);
  }
};

const handleOpenParentPopover = () => {
  if (props.isChild) {
    sideBarStore.setIsCloseComboParentActive(false);
    popoverReference.value.close();
  }
};

const previewImage = computed(() => {
  if (
    props.comboImage?.includes('cdn.shopify.com') &&
    !props.comboImage.includes('.tiff') &&
    !props.comboImage.includes('.tif')
  ) {
    return props.comboImage.replace(/(\.[^.]+)$/, '_64x64$1');
  } else {
    return props.comboImage ?? '';
  }
});

watch(isClosePopover, (newValue, oldValue) => {
  if (oldValue === undefined || newValue === oldValue) return;
  newValue ? popoverReference.value.close() : popoverReference.value.onOpen();
});
</script>

<template>
  <div class="flex justify-between gap-8" :class="cn([!isFullWidth ? 'pl-24' : ''])">
    <SettingTitle v-if="showLabel" :label="label" variant="secondary" />
    <div ref="reference" class="w-[140px] flex-shrink-0">
      <GPopover
        ref="popoverReference"
        cls="bg-dark-500"
        button-class="!w-full"
        wrap-content-class="!w-full"
        placement="right-start"
        :offset-top="-8"
        :ignore-outside-class="`gp-combo-input-${comboID}`"
        :no-padding="true"
        :placement-offset="20"
        :enable-flip="false"
        :detect-side-bar="true"
        :overlay="true"
        overlay-class="bg-none"
        @close="handleClosePopover"
        @open="handleOpenPopover">
        <div class="w-full" :class="`gp-combo-input-${comboID}`">
          <GInput
            :clear-button="!isHideClear"
            :readonly="true"
            :active="isOpenMoreSetting"
            :placeholder="placeholder"
            :prefix-icon="comboColor || comboImage ? undefined : comboIcon || 'polaris-plus'"
            :prefix-color="comboColor"
            :prefix-image="previewImage"
            :value="contentDisplay"
            :light-mode="false"
            :is-hide-clear="isHideClear"
            :style="inputStyle"
            @clear="handleClear" />
        </div>
        <template #content="{ close }">
          <div class="w-[280px]">
            <div class="border-dark-300 flex h-[52px] items-center justify-between border-b px-16">
              <div class="flex gap-4">
                <GButton
                  v-if="isChild"
                  only-icon="polaris-chevron-left"
                  type="ghost"
                  size="semi-medium"
                  @click="handleOpenParentPopover" />
                <SettingTitle :label="label" :help="help" variant="bold" />
              </div>
              <GButton
                only-icon="polaris-x"
                type="ghost"
                size="semi-medium"
                @click="!isChild ? close() : handleOpenParentPopover()" />
            </div>
            <div class="flex max-h-[calc(100vh-200px)] flex-col gap-16 py-16">
              <perfect-scrollbar>
                <div class="flex flex-col gap-16 px-16">
                  <slot></slot>
                </div>
              </perfect-scrollbar>
            </div>
          </div>
        </template>
      </GPopover>
    </div>
  </div>
</template>
