import type { SettingUIControl, SettingUIGroup, SettingUITab, SettingUIToggleSettings } from '@gem/control-v2';

export const useFlattenControlUI = () => {
  const groupControlFlatten = (controls: SettingUIControl[]) => {
    let flattenControls: SettingUIControl[] = [];
    controls?.forEach((control) => {
      if (control.type === 'compo') {
        const compoControls = control.controls || [];
        flattenControls = [...flattenControls, ...compoControls];
      } else if (control.type === 'tab') {
        const tabControls = control.tabs?.map((item) => item.controls || []).flat() || [];
        flattenControls = [...flattenControls, ...tabControls];
      } else {
        flattenControls.push(control);
      }
    });
    return flattenControls;
  };

  const toggleSettingFlatten = (toggleSettings: SettingUIToggleSettings[]) => {
    const toggleSettingsControls: SettingUIControl[] = [];
    toggleSettings.forEach((item) => {
      if (item.type === 'control') toggleSettingsControls.push(item);
      else toggleSettingsControls.push(...(item.controls || []));
    });
    return groupControlFlatten(toggleSettingsControls);
  };

  const statesSettingFlatten = (states: SettingUITab[]) => {
    const statesControls: SettingUIControl[] = [];
    states?.forEach((item) => {
      const tabControls = item.controls || [];
      statesControls.push(...tabControls);
    });
    return groupControlFlatten(statesControls);
  };

  const groupSettingsFlatten = (group: SettingUIGroup) => {
    let flattenControls: SettingUIControl[] = [];
    const moreSettings = group.moreSettings;
    const toggleSettings = group.toggleSettings || [];
    const stateSettings = group.states || [];
    const groupControls = groupControlFlatten(group.controls || []);
    const toggleSettingControls = toggleSettingFlatten(toggleSettings);
    const moreSettingControls = groupControlFlatten(moreSettings?.controls || []);
    const statesControls = statesSettingFlatten(stateSettings);
    flattenControls = [
      ...flattenControls,
      ...groupControls,
      ...toggleSettingControls,
      ...statesControls,
      ...moreSettingControls,
    ];
    return flattenControls;
  };

  return {
    groupSettingsFlatten,
  };
};
