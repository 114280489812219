import type {
  DynamicCollectionType,
  GlobalStyleResponsiveConfig,
  PickedProductType,
  ScreenType,
  Section,
  SwatchesManagerModelData,
  ThemePageType,
  Component as ElementComponent,
} from '@gem/common';
import { defineStore } from 'pinia';
import type { Component } from 'vue';

export type State = {
  controlComponents: Record<string, any>;
  editingComponentUid: string | null;
  currentDevice: ScreenType;
  searchKeyword: string;
  editingPageType: '' | ThemePageType | 'THEME_SECTION';
  section: Section | undefined;
  elementData: ElementComponent | undefined;
  pickedDynamicProduct?: PickedProductType | null;
  pickedDynamicCollection?: DynamicCollectionType;
  themePageStatus?: string;
  globalStyles?: GlobalStyleResponsiveConfig;
  shopPlan?: string;
  shopifyDomain?: string;
  goToPricing?: string;
  swatchesManagerData?: SwatchesManagerModelData;
  comboParentActiveID?: string;
  comboParentRef?: any;
  isCloseComboParentActive?: boolean;
  isOpenComboChildren?: boolean;
};

export const useSettingSideBarStore = defineStore('settingSideBar', {
  state: (): State => ({
    section: undefined,
    elementData: undefined,
    controlComponents: {},
    editingComponentUid: '',
    currentDevice: 'desktop',
    searchKeyword: '',
    editingPageType: '',
    swatchesManagerData: undefined,
    goToPricing: undefined,
    comboParentActiveID: undefined,
    isCloseComboParentActive: undefined,
    comboParentRef: undefined,
    isOpenComboChildren: undefined,
  }),

  getters: {
    getControlComponents(state) {
      return state.controlComponents;
    },

    getEditingComponentUid(state) {
      return state.editingComponentUid;
    },

    getCurrentDevice(state) {
      return state.currentDevice;
    },

    getSearchKeyword(state) {
      return state.searchKeyword;
    },

    getEditingPageType(state) {
      return state.editingPageType;
    },

    getPickedDynamicProduct(state) {
      return state.pickedDynamicProduct;
    },

    getPickedDynamicCollection(state) {
      return state.pickedDynamicCollection;
    },

    getThemePageStatus(state) {
      return state.themePageStatus;
    },

    getGlobalStyle(state) {
      return state.globalStyles;
    },

    getGoToPricing(state) {
      return state.goToPricing;
    },

    getSwatchesManagerData(state) {
      return state.swatchesManagerData;
    },

    getCompoParentRef(state) {
      return state.comboParentRef;
    },
  },

  actions: {
    setControlComponents(data: Record<string, Component>) {
      this.controlComponents = data;
    },
    setEditingComponentUid(editingComponentUid: string | null) {
      this.editingComponentUid = editingComponentUid;
    },
    setCurrentDevice(currentDevice: ScreenType) {
      this.currentDevice = currentDevice;
    },
    setSearchKeyword(searchKeyword: string) {
      this.searchKeyword = searchKeyword;
    },
    setEditingPageType(editingPageType: '' | ThemePageType | 'THEME_SECTION') {
      this.editingPageType = editingPageType;
    },
    setPickedDynamicProduct(pickedDynamicProduct?: PickedProductType | null) {
      this.pickedDynamicProduct = pickedDynamicProduct;
    },
    setPickedDynamicCollection(pickedDynamicCollection?: DynamicCollectionType) {
      this.pickedDynamicCollection = pickedDynamicCollection;
    },
    setThemePageStatus(themePageStatus?: string) {
      this.themePageStatus = themePageStatus;
    },
    setGlobalStyle(globalStyles?: GlobalStyleResponsiveConfig) {
      this.globalStyles = globalStyles;
    },
    setShopPlan(shopPlan?: string) {
      this.shopPlan = shopPlan;
    },
    setShopifyDomain(shopifyDomain?: string) {
      this.shopifyDomain = shopifyDomain;
    },
    setGoToPricing(value?: string) {
      this.goToPricing = value;
    },
    setSwatchesManagerData(value?: SwatchesManagerModelData) {
      this.swatchesManagerData = value;
    },
    setSection(section?: Section) {
      this.section = section;
    },
    setElementData(component?: ElementComponent) {
      this.elementData = component;
    },
    setComboParentActiveID(id?: string) {
      this.comboParentActiveID = id;
    },
    setComboParentRef(ref?: any) {
      this.comboParentRef = ref;
    },
    setIsCloseComboParentActive(isClose: boolean) {
      this.isCloseComboParentActive = isClose;
    },
    setIsOpenComboChildren(isClose: boolean) {
      this.isOpenComboChildren = isClose;
    },
  },
});

export default useSettingSideBarStore;
