<script lang="ts" setup>
import type { OptionItem } from '@gem/control-v2';
import { GIcon } from '@gem/icons';
import { GTooltip } from '@gem/uikit';

type PropsType = {
  id: string;
  value?: any;
  options?: OptionItem[];
  disableToggle?: boolean;
  readonly?: boolean;
};
const props = defineProps<PropsType>();
const emit = defineEmits<{
  (e: 'controlChange', controlId: string, value?: OptionItem['value']): void;
}>();

const change = (v?: OptionItem['value']) => {
  if (props.value === v || props.disableToggle) {
    return;
  }

  emit('controlChange', props.id, v);
};
</script>

<template>
  <div class="gemx-control">
    <slot name="label"></slot>
    <div data-test="editor-control-segment" class="bg-dark-400 flex h-36 w-full items-center gap-4 rounded-xl p-[2px]">
      <template v-for="item in options?.filter((item) => !item.hide)" :key="item.value">
        <div
          class="text-12 hover:text-light-200 flex h-full flex-1 cursor-pointer select-none items-center justify-center rounded-[6px] text-center font-medium transition-all"
          :class="
            value === item.value ? '!bg-dark-200 !text-light-200 cursor-default' : `text-text-dark-100 bg-dark-400`
          "
          @click.stop="change(item.value)">
          <GTooltip
            placement="top"
            :disabled="!item.tooltip"
            class="!flex h-full w-full items-center justify-center"
            :class="{
              'w-[86px]': item.label === 'Net Discount',
            }">
            <GIcon v-if="item.iconName" :name="item.iconName" />
            <span v-else class="[&_>_svg]:h-20" v-html="item.label"></span>
            <template v-if="item.tooltip" #content>
              <div class="text-light-100 text-12">{{ item.tooltip }}</div>
            </template>
          </GTooltip>
        </div>
      </template>
    </div>
    <slot name="info"></slot>
  </div>
</template>
