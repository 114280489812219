<script setup lang="ts">
import { ComboSettingLayout, type ControlChangeValueType, type SettingUIControl } from '@gem/control-v2';
import { GButton, GPopover, GInput } from '@gem/uikit-v2';
import SettingTitle from './SettingTitle.vue';
import ControlSettings from './ControlSettings.vue';
import { computed, ref } from 'vue';
import { cn } from '@gem/common';

type ComboSettingProps = {
  data: SettingUIControl;
};

const emit = defineEmits<{
  (event: 'controlChange', value: ControlChangeValueType): void;
  (event: 'controlOnChange', value: ControlChangeValueType): void;
}>();

const props = defineProps<ComboSettingProps>();

const controlIDGetContent = computed(() => props.data.getValueFromSettingID);

const contentDisplay = ref(getContentByControlID(props.data.getValueFromSettingID) || props.data.placeholder);
const comboIcon = ref(props.data.iconName);
const isFullWidth = computed(() =>
  props.data?.options?.fullWidth === undefined ? true : props.data?.options?.fullWidth,
);

function getContentByControlID(controlID?: string) {
  const control = props.data.controls?.find((item) => item.mapTo?.control?.id === controlID)?.mapTo?.control;
  return control?.default;
}

const handelControlChange = (data: ControlChangeValueType) => {
  if (data.controlId === controlIDGetContent.value) {
    contentDisplay.value = data.val;
  }
  emit('controlChange', data);
};

const handelControlOnChange = (data: ControlChangeValueType) => {
  emit('controlOnChange', data);
};
</script>

<template>
  <ComboSettingLayout
    :label="data?.label"
    :is-full-width="isFullWidth"
    :help="data.help"
    :combo-icon="comboIcon"
    :content-display="contentDisplay">
    <ControlSettings
      v-if="data.controls?.length"
      :controls="data.controls"
      @control-change="handelControlChange"
      @control-on-change="handelControlOnChange" />
  </ComboSettingLayout>
</template>
