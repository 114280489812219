<script setup lang="ts">
import { computed } from 'vue';
import type { DisplayType } from './types';

import type { OptionItem } from '@gem/control-v2';
import { ControlLayout, Segment } from '@gem/control-v2';

type RowLayoutColumnWidthProps = {
  displayType?: DisplayType;
};

const props = defineProps<RowLayoutColumnWidthProps>();

const emit = defineEmits<(e: 'controlChange', displayType: DisplayType) => void>();

const displayValue = computed(() => {
  return props.displayType ?? 'fill';
});
const displayOptions: {
  label: string;
  value: DisplayType;
}[] = [
  {
    label: 'Yes',
    value: 'fit',
  },
  {
    label: 'No',
    value: 'fill',
  },
];

const handleChangeDisplay = (controlID: string, value: OptionItem['value']) => {
  if (displayValue.value === value) return;
  emit('controlChange', value as DisplayType);
};
</script>

<template>
  <ControlLayout :label="{ en: 'Fit to content' }" label-variant="secondary">
    <template #control>
      <Segment id="displayType" :value="displayValue" :options="displayOptions" @control-change="handleChangeDisplay" />
    </template>
  </ControlLayout>
</template>
