<script setup lang="ts">
import type { ScreenType } from '@gem/common';
import { cn } from '@gem/common';
import { computed, useSlots, type StyleValue } from 'vue';
import type { LabelVariant, LabelWithLang, SettingUIHelpType } from '../types';
import useSettingSideBarStore from '../../hooks/useSettingSideBarStore';
import { SettingTitle } from '@gem/control-v2';
import { GTooltip } from '@gem/uikit';
withDefaults(
  defineProps<{
    layout?: 'vertical' | 'horizontal';
    isFullWidth?: boolean;
    disabled?: boolean;
    styles?: StyleValue;
    isControlAutoWidth?: boolean;
    label?: LabelWithLang;
    labelVariant?: LabelVariant;
    labelShow?: boolean;
    showDevices?: boolean;
    help?: SettingUIHelpType;
    align?: 'top' | 'center';
  }>(),
  {
    labelShow: true,
    isFullWidth: true,
    align: 'center',
    labelVariant: 'secondary',
  },
);

const slots = useSlots();
const settingSideBarStore = useSettingSideBarStore();
const currentDevice = computed(() => settingSideBarStore.currentDevice);
</script>

<template>
  <div
    class="relative flex w-full justify-between"
    :class="
      cn([
        layout === 'vertical' ? 'flex-col justify-start gap-12' : 'items-center justify-between gap-4',
        !isFullWidth ? 'pl-24' : '',
        disabled ? 'opacity-30' : '',
        align === 'top' ? 'items-start' : '',
      ])
    "
    :style="styles">
    <div
      v-if="disabled"
      class="hover:bg-underlay-red-100 rounded-12 absolute top-0 left-0 z-[100] h-full w-full cursor-not-allowed">
      <GTooltip
        placement="top"
        class="!block h-full w-full"
        content-class="max-w-[200px] whitespace-normal"
        :is-teleport="true">
        <template #content>
          <slot name="tooltip"></slot>
        </template>
      </GTooltip>
    </div>
    <slot v-if="slots.locked" name="locked"></slot>
    <template v-else>
      <SettingTitle
        v-if="labelShow"
        :label="label"
        :help="help"
        :variant="labelVariant"
        :show-devices="showDevices"
        :current-device="currentDevice" />
      <div
        :class="
          cn([
            layout === 'vertical' ? 'w-full' : 'w-full max-w-[140px] flex-shrink-0',
            isControlAutoWidth ? '!w-auto' : '',
          ])
        ">
        <slot name="control"></slot>
      </div>
    </template>
  </div>
</template>
