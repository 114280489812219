<script setup lang="ts">
import { computed, ref } from 'vue';
import type { ControlChangeValueType, SettingUITab } from './types';
import ControlSettings from './ControlSettings.vue';

type ElementSettingStateProps = {
  data?: SettingUITab[];
};

const emit = defineEmits<{
  (event: 'controlChange', value: ControlChangeValueType): void;
  (event: 'controlOnChange', value: ControlChangeValueType): void;
}>();

const props = defineProps<ElementSettingStateProps>();

const currentTab = ref(0);
function changeTab(index: number) {
  currentTab.value = index;
}

const tabs = computed(() => {
  return props.data?.filter((tab) => !tab.hide);
});

const handleControlChange = (data: ControlChangeValueType) => {
  emit('controlChange', data);
};

const handleControlOnChange = (data: ControlChangeValueType) => {
  emit('controlOnChange', data);
};
</script>
<template>
  <div v-if="data?.length" class="gemx-tab w-full">
    <div v-if="tabs?.length" class="mb-16 w-full">
      <div class="border-dark-300 ml-0 flex items-center gap-4 rounded-xl border-[1px] border-solid p-4">
        <div v-for="(controlUI, index) in tabs" :key="index" class="flex flex-1 items-center justify-center">
          <button
            :data-test="`editor-control-tab-title-${
              controlUI?.controls && controlUI?.controls[index] && controlUI?.controls[index].setting?.state
            }`"
            class="h-[28px] w-full rounded-[6px] text-center text-[12px] capitalize transition-colors duration-200 focus:outline-none"
            :class="[
              currentTab === index
                ? 'text-light-450 bg-underlay-blue-150'
                : 'text-text-dark-300   hover:bg-dark-250 border-transparent',
            ]"
            @click="changeTab(index)">
            <p v-if="controlUI?.label?.en" class="text-12 font-medium">
              {{ controlUI.label?.en }}
            </p>
          </button>
        </div>
      </div>
    </div>
    <template v-for="(groupControl, tabIndex) in tabs" :key="tabIndex">
      <template v-if="tabIndex == currentTab">
        <ControlSettings
          :controls="groupControl.controls || []"
          @control-change="handleControlChange"
          @control-on-change="handleControlOnChange" />
      </template>
    </template>
  </div>
</template>
