<script setup lang="ts">
import { ControlLayout, type ControlChangeValueType, type SettingUIControl } from '@gem/control-v2';
import useControlData from '../hooks/useControlData';
import SettingTitle from './SettingTitle.vue';
import ControlLocked from './ControlLocked.vue';
import type { ActionOptions, Plan, ScreenType, SwatchesOptionType } from '@gem/common';
import { computed } from 'vue';
import useSettingSideBarStore from '../hooks/useSettingSideBarStore';

type ControlSettingProps = {
  data: SettingUIControl;
  hiddenDevice?: boolean;
};

const emit = defineEmits<{
  (event: 'controlChange', value: ControlChangeValueType): void;
  (event: 'controlOnChange', value: ControlChangeValueType): void;
}>();

const props = defineProps<ControlSettingProps>();

const settingSideBarStore = useSettingSideBarStore();
const currentDevice = computed(() => settingSideBarStore.currentDevice);
const shopPlan = computed(() => settingSideBarStore.shopPlan);

const controlData = computed(() => props.data);

const hasDevices = computed(() => !!props.data?.mapTo?.control.devices);

const isFullWidth = computed(() =>
  props.data?.options?.fullWidth === undefined ? true : props.data?.options?.fullWidth,
);

const isLockedControl = computed(() => {
  const lockedOnPlans = props.data.options?.lockedOnPlans;
  if (lockedOnPlans && shopPlan.value) {
    return lockedOnPlans.includes(shopPlan.value as Plan);
  }

  return false;
});

const nearestSupportedPlan = computed(() => {
  return props.data.options?.nearestSupportedPlan;
});

const CONTROL_AUTO_WIDTH = ['colorpicker'];
const isControlAutoWidth = computed(() => CONTROL_AUTO_WIDTH.includes(props.data.mapTo?.control.type || ''));

const { controlName, controlProps } = useControlData({ controlData: controlData });

const modifyValue = (
  controlId: string,
  val?: any,
  screen?: ScreenType,
  options?: ActionOptions,
  customizeOldData?: string,
) => {
  return {
    val,
    controlId,
    screen,
    options,
    customizeOldData,
    state: props.data.setting?.state,
    default: controlProps?.value?.default,
    controlType: props.data.mapTo?.control.type,
    controlChangeTrigger: props.data.controlChangeTrigger,
    tabID: controlProps?.value?.tabId,
    devices: controlProps?.value?.devices,
    linkWithSetting: controlProps?.value?.linkWithSetting,
  };
};

const handleControlChange = (
  controlId: string,
  val?: any,
  screen?: ScreenType,
  options?: ActionOptions,
  customizeOldData?: string,
) => {
  emit('controlChange', modifyValue(controlId, val, screen, options, customizeOldData));
};

const handleControlOnChange = (
  controlId: string,
  val?: any,
  screen?: ScreenType,
  options?: ActionOptions,
  customizeOldData?: string,
) => {
  emit('controlOnChange', modifyValue(controlId, val, screen, options, customizeOldData));
};

const openSwatchesManagement = (optionType: SwatchesOptionType, optionTitle: string) => {
  settingSideBarStore.setSwatchesManagerData({
    openManageSwatches: true,
    optionType: optionType ?? '',
    optionTitle: optionTitle ?? '',
  });
};
</script>

<template>
  <ControlLayout
    :is-full-width="isFullWidth"
    :layout="data.layout"
    :disabled="data.disabled"
    :styles="data.options?.styles"
    :is-control-auto-width="isControlAutoWidth"
    :label="data.label"
    :help="data.help"
    :label-variant="data.options?.labelVariant || 'secondary'"
    :show-devices="hasDevices && !hiddenDevice && !data.options?.hiddenSwitchDeviceIcon"
    :label-show="!data?.options?.hideLabel && !data.options?.labelInsideControl">
    <template #tooltip>
      {{ data?.options?.disableMessage || 'Settings cannot be used at this time' }}
    </template>
    <template v-if="isLockedControl && data.label && nearestSupportedPlan" #locked>
      <ControlLocked
        :label="data.label?.en || ''"
        :nearest-supported-plan="nearestSupportedPlan"
        :control="data.mapTo?.control.type">
        <template #label>
          <SettingTitle :label="data.label" :variant="data.options?.labelVariant || 'secondary'" />
        </template>
      </ControlLocked>
    </template>
    <template #control>
      <component
        :is="controlName"
        v-bind="controlProps"
        @open-swatches-modal="openSwatchesManagement"
        @control-change="handleControlChange"
        @control-on-change="handleControlOnChange">
        <template v-if="data.options?.labelInsideControl" #label>
          <SettingTitle
            :label="data.label"
            :help="data.help"
            :variant="data.options?.labelVariant || 'secondary'"
            :show-devices="hasDevices && !hiddenDevice"
            :current-device="currentDevice" />
        </template>
      </component>
    </template>
  </ControlLayout>
</template>
