<script setup lang="ts">
import type { TAB_TYPE } from './types';
import type { ColorPickerProps } from './types';
import { ComboSettingLayout, ControlLayout, Segment } from '@gem/control-v2';
import { useColorPicker } from './hooks/useColorPicker';
import { type ColorSuggestions } from '@gem/common';
import SolidColorPicker from './components/SolidColorPicker.vue';
import GradientColorPicker from './components/GradientColorPicker.vue';
import { ref } from 'vue';
import { makeGradientColorFormColor } from './helpers';

const props = defineProps<ColorPickerProps>();

const emit = defineEmits<{
  (e: 'controlChange', controlId: string, value?: string): void;
  (e: 'controlOnChange', controlId: string, value?: string): void;
  (e: 'saveMyColors', value?: ColorSuggestions): void;
}>();

const solidColorCache = ref();
const gradientColorCache = ref();

const { displayValue, colorMode, handleSetDefaultValue, handleClearColor } = useColorPicker(props, emit);

const handleControlChange = (controlId: string, value?: string) => {
  emit('controlChange', controlId, value);
};

const handleControlOnChange = (controlId: string, value?: string) => {
  emit('controlChange', controlId, value);
};

const handleSaveMyColors = (value?: ColorSuggestions) => {
  emit('saveMyColors', value);
};

const handleChangeMode = (value: string) => {
  colorMode.value = value as TAB_TYPE;
  if (value === 'gradient') {
    solidColorCache.value = props.value;
    if (gradientColorCache.value) handleControlChange(props.id, gradientColorCache.value);
    else handleControlChange(props.id, makeGradientColorFormColor(solidColorCache.value));
  } else {
    gradientColorCache.value = props.value;
    if (solidColorCache.value) handleControlChange(props.id, solidColorCache.value);
    else handleSetDefaultValue(true);
  }
};
</script>
<template>
  <ComboSettingLayout
    placeholder="Add..."
    :label="{ en: isBackground ? 'Background Color' : 'Color' }"
    :show-label="false"
    :is-full-width="true"
    :content-display="displayValue?.replace('#', '')"
    :combo-color="displayValue"
    :is-child="isChild"
    @click="handleSetDefaultValue"
    @clear="handleClearColor">
    <ControlLayout layout="vertical">
      <template #control>
        <div class="flex flex-col gap-16">
          <Segment
            v-if="enableGradient"
            id="mode"
            :value="colorMode"
            :options="[
              { value: 'solid', label: 'Solid' },
              { value: 'gradient', label: 'Gradient' },
            ]"
            @control-change="(_, value) => handleChangeMode(value)" />
          <SolidColorPicker
            v-if="colorMode === 'solid'"
            v-bind="props"
            @control-change="handleControlChange"
            @control-on-change="handleControlOnChange"
            @save-my-colors="handleSaveMyColors" />
          <GradientColorPicker
            v-if="colorMode === 'gradient'"
            v-bind="props"
            @control-change="handleControlChange"
            @control-on-change="handleControlOnChange"
            @save-my-colors="handleSaveMyColors" />
        </div>
      </template>
    </ControlLayout>
  </ComboSettingLayout>
</template>
