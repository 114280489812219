<script setup lang="ts">
import { BackgroundPosition, Input, LayoutSegment, Segment, Select, Textarea } from '@gem/control';
import { BG_ATTACHMENT_OPTIONS, BG_REPEAT_OPTIONS, BG_SIZE_OPTIONS } from './const';
import { useBackgroundImage } from './hooks/useBackgroundImage';
import type { BackgroundImageProps } from './types';
import { ComboSettingLayout, ControlLayout, SettingTitle, Toggle } from '@gem/control-v2';

const props = defineProps<BackgroundImageProps>();
const emit = defineEmits<{
  (e: 'controlChange', controlId: string, value?: BackgroundImageProps['value']): void;
  (e: 'controlOnChange', controlId: string, value?: BackgroundImageProps['value']): void;
}>();

const { handleChangeValue, handleClear, handleSetDefaultValue } = useBackgroundImage(props, emit);
</script>

<template>
  <ComboSettingLayout
    placeholder="Add..."
    :label="{ en: 'Background Image' }"
    :show-label="false"
    :combo-image="value?.image?.src"
    :content-display="value?.image?.src"
    :is-full-width="true"
    @click="handleSetDefaultValue"
    @clear="handleClear">
    <div class="flex flex-col gap-12">
      <div class="border-dark-300 flex flex-col gap-16 border-b pb-20">
        <ControlLayout layout="vertical" :label="{ en: 'Source' }" label-variant="primary">
          <template #control>
            <slot></slot>
          </template>
        </ControlLayout>

        <ControlLayout :label="{ en: 'Scale' }" :label-variant="'secondary'">
          <template #control>
            <LayoutSegment
              id="bgSize"
              :value="value?.size || 'cover'"
              :options="BG_SIZE_OPTIONS"
              :enable-item-background="true"
              :enable-tooltip="true"
              icon-view-box="0 0 66 48"
              @control-change="(_, value) => handleChangeValue('size', value)" />
          </template>
        </ControlLayout>

        <ControlLayout :label="{ en: 'Position' }" :label-variant="'secondary'" align="top">
          <template #control>
            <BackgroundPosition
              id="bgPosition"
              control-class="place-self-end"
              size-class="!w-full !aspect-[4/3]"
              :value="value?.position || { x: 50, y: 50 }"
              @control-change="(_, value) => handleChangeValue('position', value)">
            </BackgroundPosition>
          </template>
        </ControlLayout>

        <ControlLayout :label="{ en: 'Repeat' }" :label-variant="'secondary'">
          <template #control>
            <Segment
              id="bgRepeat"
              :value="value?.repeat || 'no-repeat'"
              :options="BG_REPEAT_OPTIONS"
              :icon-custom="true"
              @control-change="(_, value) => handleChangeValue('repeat', value)">
            </Segment>
          </template>
        </ControlLayout>

        <ControlLayout :label="{ en: 'Attachment' }" :label-variant="'secondary'">
          <template #control>
            <Select
              id="bgAttachment"
              :value="value?.attachment || 'scroll'"
              :options="BG_ATTACHMENT_OPTIONS"
              @control-change="(_, value) => handleChangeValue('attachment', value)">
            </Select>
          </template>
        </ControlLayout>
      </div>
      <div v-if="!isBackground" class="border-dark-300 flex flex-col gap-12 border-b pb-20">
        <SettingTitle :label="{ en: 'SEO' }" variant="primary" />
        <div class="flex flex-col gap-16">
          <ControlLayout :label="{ en: 'Alt text' }" align="top">
            <template #control>
              <Textarea
                :value="value?.altText"
                :auto-height="true"
                :default-rows="3"
                placeholder="E.g: Classic white crewneck t-shirt with minimalist logo"
                @control-change="(_, value) => handleChangeValue('altText', value)" />
            </template>
          </ControlLayout>
          <ControlLayout :label="{ en: 'Image title' }">
            <template #control>
              <Input
                id="bgImageTitle"
                :value="value?.imageTitle"
                placeholder="E.g: White t-shirt"
                @control-change="(_, value) => handleChangeValue('imageTitle', value)" />
            </template>
          </ControlLayout>
        </div>
      </div>
      <div class="flex flex-col gap-12 pb-12">
        <SettingTitle :label="{ en: 'Optimize LCP' }" variant="primary" />
        <ControlLayout :label="{ en: 'Preload' }">
          <template #control>
            <Toggle
              :value="value?.preload || false"
              @control-change="(_, value) => handleChangeValue('preload', value)" />
          </template>
        </ControlLayout>
      </div>
    </div>
  </ComboSettingLayout>
</template>
