<script setup lang="ts">
import TypographyFormEdit from './TypographyFormEdit.vue';
import type { TypoControlProps } from './types';
import { useTypoGraphyControl } from './hooks/useTypographyControl';
import { ControlLayout } from '@gem/control-v2';

const props = defineProps<TypoControlProps>();

const emit = defineEmits<{
  (e: 'controlChange', controlId: string, value: TypoControlProps['value']): void;
  (e: 'controlOnChange', controlId: string, value: TypoControlProps['value']): void;
  (e: 'onClickSubAction', type: string, value?: any): void;
  (e: 'changeTypo', type: string): void;
  (e: 'saveColor', newColorList: {}): void;
}>();

const {
  displayValue,
  displayAttr,
  typoName,
  fontWeightOptions,
  handleSaveColor,
  handleControlChange,
  handleControlOnChange,
  handleChangeTypo,
} = useTypoGraphyControl(props, emit);
</script>

<template>
  <div class="flex w-full flex-col gap-16">
    <ControlLayout :label="{ en: 'Styles' }" :label-variant="'secondary'">
      <template #control>
        <g-select
          :options="typos"
          data-test="editor-control-typo-select"
          :active-id="value?.type"
          @select="handleChangeTypo">
          <template #button-content>
            <span
              class="text-12 truncate whitespace-nowrap"
              :class="{
                italic: isCustom,
              }">
              {{ typoName }}{{ isCustom ? '*' : '' }}
            </span>
          </template>
          <template #content="{ item, onSelect, isActive, isHoverSelect }">
            <div
              class="hover:bg-light-100/20 text-light-100 text-12 group relative flex cursor-pointer select-none items-center truncate whitespace-nowrap rounded-xl py-8 pl-24 pr-10 leading-[14px]"
              :class="{ 'bg-text-light-300 !text-light-450': isActive && !isHoverSelect }"
              :data-value="item.id"
              @click="onSelect(item)">
              <span v-if="isActive" class="absolute inset-y-0 left-4 flex items-center">
                <GBaseIcon name="status-check" width="16" height="16" />
              </span>
              <p class="font-regular flex w-full items-center justify-between truncate">
                <span class="truncate">{{ item.name }}</span>
                <span class="text-text-dark-300">{{ item.value }}</span>
              </p>
            </div>
          </template>
        </g-select>
      </template>
    </ControlLayout>

    <TypographyFormEdit
      :font-options="fontOptions"
      :value="displayValue"
      :attrs="displayAttr"
      :overlay-container="overlayContainer"
      :current-screen="currentScreen"
      :hidden-setting="hiddenSetting"
      :font-weight-options="fontWeightOptions"
      :search-query="searchQuery"
      :current-global-style-color="currentGlobalStyleColor"
      :global-colors="globalColors"
      :current-component-setting="currentComponentSetting"
      :global-style-font="globalStyleFont"
      :editor-store="editorStore"
      :shop-store="shopStore"
      :font-backup="fontBackup"
      :font-uploads="fontUploads"
      :theme-fonts="themeFonts"
      :tag="tag"
      :disable-collapse="disableCollapse"
      @save-color="handleSaveColor"
      @change="handleControlChange"
      @on-change="handleControlOnChange" />
  </div>
</template>
