import type { FontName, TypographyV2Props, TypographyFamily, TypographyFontOption } from '@gem/common';
import { cloneDeepObject } from '@gem/uikit/src/types/common';
import type { Ref } from 'vue';
import { inject, computed } from 'vue';
import { useCheckIsRecentFont } from '../composables/useCheckIsRecentFont';
import type { FontFamilySelectProps } from '../types';

export const useFontFamilySelect = (fontFamilyProps: FontFamilySelectProps, keyword: Ref<string>, emit: any) => {
  const { isRecentFont } = useCheckIsRecentFont();
  const pageMetaRecentlyValueParse = inject<Ref<TypographyFamily>>('pageMetaRecentlyValueParse');

  const mapFontOptions = computed(() => {
    const options = fontFamilyProps.options ?? [];
    return [...options];
  });

  const items = computed(() => {
    return (
      mapFontOptions.value?.filter((v) => {
        return (
          v.label?.toLowerCase().includes(keyword.value.trim().toLowerCase()) ||
          v.value?.toLowerCase().includes(keyword.value.trim().toLowerCase())
        );
      }) ?? []
    );
  });

  const mixItems = computed(() => {
    const createFontList = (fonts: any, label: string, isRecently?: boolean) => {
      if (!fonts) return [];
      let dataFonts = cloneDeepObject(fonts);

      if (isRecently && listFontTheme.value.length === 1) {
        dataFonts = dataFonts.filter((item: any) => item.value.value !== '--g-theme-font-heading');
      }
      const fontList = dataFonts.map((item: any) => {
        if (isRecently) {
          return {
            value: typeof item.value === 'string' ? item.value : item.value.value,
            type: typeof item.value === 'string' ? 'google' : item.value.type,
            isRecently: true,
          };
        }

        return {
          ...item,
          type: item?.type || 'google',
        };
      });

      if (fontList.length > 0) {
        fontList.unshift({
          label,
          isLabel: true,
        });
      }

      return fontList;
    };
    const recentlyFonts = createFontList(listFontRecently.value, 'Recently', true);
    const customFonts = createFontList(listFontCustom.value, 'Custom font');
    const themeFonts = createFontList(listFontTheme.value, 'Theme font');
    const googleFonts = createFontList(listFontGoogle.value, 'Google font');

    return [...(!keyword.value ? recentlyFonts : []), ...customFonts, ...themeFonts, ...googleFonts];
  });

  const listFontRecently = computed(() => {
    const value = pageMetaRecentlyValueParse?.value;
    return Array.isArray(value) ? value : [];
  });

  const listFontCustom = computed(() => {
    const fontUploads = fontFamilyProps.fontUploads?.filter((item) => !isRecentFont(item)) || [];
    const currentFont = items.value.filter((item) => item.type === 'custom' && !isRecentFont(item));

    return mergeFontCustomArrays(fontUploads, currentFont);
  });

  const listFontTheme = computed(() => {
    return items.value.filter((item) => item.type === 'theme' && !isRecentFont(item));
  });

  const listFontGoogle = computed(() => {
    return items.value.filter((item) => (item.type === 'google' || !item.type) && !isRecentFont(item));
  });

  const currentOption = computed(() => {
    if (!fontFamilyProps.value) return;

    return getCurrentOption(fontFamilyProps.value);
  });

  const currentIndex = computed(() => {
    return items.value.findIndex((item) => item.value === fontFamilyProps.value);
  });

  const getCurrentOption = (fontFamily: TypographyV2Props['fontFamily']): TypographyFontOption | undefined => {
    if (typeof fontFamily === 'string') {
      const font = mapFontOptions.value?.find((item) => item.value === fontFamily);

      return (
        font ?? {
          label: fontFamily,
          value: fontFamily,
          type: 'google',
        }
      );
    }

    if (!fontFamily?.value) return;

    const font = mapFontOptions.value?.find((item) => item.value === fontFamily.value);
    return (
      font ?? {
        label: fontFamily.value,
        value: fontFamily.value,
        type: fontFamily.type,
      }
    );
  };

  function mergeFontCustomArrays(
    fontUploads: TypographyFontOption[],
    customFonts: TypographyFontOption[],
  ): TypographyFontOption[] {
    const combinedArray = [...fontUploads, ...customFonts];
    const mergedMap = combinedArray.reduce<Map<string, TypographyFontOption>>((acc, obj) => {
      if (acc.has(obj?.value)) {
        acc.set(obj?.value, { ...acc.get(obj?.value), ...obj });
      } else {
        acc.set(obj?.value, { ...obj });
      }
      return acc;
    }, new Map());

    return Array.from(mergedMap.values());
  }

  const resolveFontValue = (fontValue: string): string => {
    if (fontValue?.includes('--g-theme-font')) {
      const typeValue = fontValue.replace('--g-theme-font-', '') as FontName;
      return fontFamilyProps.themeFonts?.[typeValue]?.family || '';
    }
    return fontValue;
  };

  const isCheckedFontItem = ({ value, type }: TypographyFamily): boolean => {
    const dataFont = fontFamilyProps.value;

    if (typeof dataFont === 'string') {
      return resolveFontValue(value) === resolveFontValue(dataFont);
    }

    const resolvedValueA = resolveFontValue(value);
    const resolvedValueB = resolveFontValue(dataFont?.value || '');

    return resolvedValueA === resolvedValueB && type === dataFont?.type;
  };

  const getLabelFont = (value?: string) => {
    if (value?.includes('--g-theme-font')) {
      const typeValue = value.replace('--g-theme-font-', '') as FontName;
      return fontFamilyProps.themeFonts?.[typeValue]?.family ?? value;
    }

    return value;
  };

  const getStyleFontFamily = (font: string) => {
    if (['sans-serif'].includes(font)) {
      return font;
    }
    if (font?.includes('--g-theme-font')) {
      return `var(${font})`;
    }
    return `'${font}'`;
  };

  const getVariantStyleFontFamily = (value?: string, type?: string): string | undefined => {
    if (type === 'theme') {
      if (value === fontFamilyProps.themeFonts?.heading?.family) {
        return `--g-theme-font-heading`;
      }
      if (value === fontFamilyProps.themeFonts?.body?.family) {
        return `--g-theme-font-body`;
      }
    }

    return;
  };

  const loadGoogleFontByName = (fontFamily: string) => {
    const fontElementSettingClass = 'google-fonts-element';
    const fontUrl = `https://fonts.googleapis.com/css?family=${fontFamily}`;
    const fontLinkElement = document.querySelector(`.${fontElementSettingClass}[data-font="${encodeURI(fontFamily)}"]`);
    if (fontLinkElement) {
      if (fontLinkElement.getAttribute('href') !== fontUrl) {
        fontLinkElement.setAttribute('href', fontUrl);
      }
    } else {
      const link = document.createElement('link');
      link.className = fontElementSettingClass;
      link.dataset.font = encodeURI(fontFamily);
      link.href = fontUrl;
      link.rel = 'stylesheet';
      document.head.appendChild(link);
    }
  };

  const onUploadFont = (event: Event) => {
    keyword.value = '';
    emit('handleChangeFile', event);
  };

  const onHandleSaveFontMeta = (data: TypographyFamily) => {
    const payload = cloneDeepObject(data);
    const variantStyle = getVariantStyleFontFamily(data.value, data.type);
    payload.value = variantStyle ?? data.value;

    emit('handleSaveFontMeta', payload, 'recently');
  };

  return {
    mixItems,
    currentOption,
    currentIndex,
    getLabelFont,
    isCheckedFontItem,
    loadGoogleFontByName,
    getStyleFontFamily,
    getVariantStyleFontFamily,
    onUploadFont,
    onHandleSaveFontMeta,
  };
};
