<script setup lang="ts">
import type { ColorSuggestions } from '@gem/common';
import { ComboSettingLayout, ControlLayout, Segment } from '@gem/control-v2';
import ColorPicker from '../color-picker/ColorPicker.vue';
import { useBoxShadowSettings } from './useBoxShadowSetting';
import { AngleNoInput, InputUnit } from '@gem/control';
import type { ShadowSettingProps } from './types';

const props = defineProps<ShadowSettingProps>();

const emit = defineEmits<{
  (e: 'controlChange', controlId?: string, value?: any['value']): void;
  (e: 'controlOnChange', controlId?: string, value?: any['value']): void;
  (e: 'updateColorSuggestion', value?: ColorSuggestions): void;
}>();

const {
  shadowValue,
  selectedOption,
  displayComboValue,
  shadowPresets,
  isCustomShadow,
  handleSetDefaultValue,
  handleClearShadow,
  handleUpdateSetting,
  handleUpdateSuggestionColor,
} = useBoxShadowSettings(props, emit);
</script>
<template>
  <ComboSettingLayout
    :id="id"
    placeholder="Add..."
    :is-parent="true"
    :label="{ en: 'Shadow' }"
    :show-label="false"
    :is-full-width="true"
    :content-display="displayComboValue"
    :combo-icon="selectedOption?.iconName"
    @click="handleSetDefaultValue"
    @clear="handleClearShadow">
    <ControlLayout :label="{ en: 'Type' }" layout="vertical">
      <template #control>
        <Segment
          :id="'style'"
          background-class="bg-dark-400"
          :value="shadowValue?.type"
          :options="shadowPresets"
          @control-change="(_: any, value: string) => handleUpdateSetting('type', 'controlChange', value)" />
      </template>
    </ControlLayout>
    <ControlLayout v-if="isCustomShadow" :label="{ en: 'Direction' }" layout="horizontal" align="top">
      <template #control>
        <AngleNoInput
          id="angle"
          :value="shadowValue?.angle"
          @control-change="(_: any, value: string) => handleUpdateSetting('angle', 'controlChange', value)"
          @control-on-change="(_: any, value: string) => handleUpdateSetting('angle', 'controlOnChange', value)">
        </AngleNoInput>
      </template>
    </ControlLayout>
    <ControlLayout :label="{ en: 'Color' }" layout="horizontal">
      <template #control>
        <ColorPicker
          :id="'color'"
          :value="shadowValue?.color"
          :is-child="true"
          @save-my-colors="handleUpdateSuggestionColor"
          @control-change="(_: any, value: string) => handleUpdateSetting('color', 'controlChange', value)"
          @control-on-change="(_: any, value: string) => handleUpdateSetting('color', 'controlOnChange', value)" />
      </template>
    </ControlLayout>
    <ControlLayout v-if="isCustomShadow" :label="{ en: 'Distance' }" layout="horizontal">
      <template #control>
        <InputUnit
          id="distance"
          class="mb-8"
          input-type="number"
          :units="['px']"
          :value="shadowValue?.distance"
          @control-change="(_: any, value: string) => handleUpdateSetting('distance', 'controlChange', value)"
          @control-on-change="(_: any, value: string) => handleUpdateSetting('distance', 'controlOnChange', value)" />
      </template>
    </ControlLayout>
    <ControlLayout v-if="isCustomShadow" :label="{ en: 'Blur' }" layout="horizontal">
      <template #control>
        <InputUnit
          id="blur"
          class="mb-8"
          input-type="number"
          :units="['px']"
          :min="0"
          :value="shadowValue?.blur"
          @control-change="(_: any, value: string) => handleUpdateSetting('blur', 'controlChange', value)"
          @control-on-change="(_: any, value: string) => handleUpdateSetting('blur', 'controlOnChange', value)" />
      </template>
    </ControlLayout>
    <ControlLayout v-if="isCustomShadow && type !== 'text-shadow'" :label="{ en: 'Size' }" layout="horizontal">
      <template #control>
        <InputUnit
          id="spread"
          class="mb-8"
          input-type="number"
          :units="['px']"
          :value="shadowValue?.spread"
          @control-change="(_: any, value: string) => handleUpdateSetting('spread', 'controlChange', value)"
          @control-on-change="(_: any, value: string) => handleUpdateSetting('spread', 'controlOnChange', value)" />
      </template>
    </ControlLayout>
  </ComboSettingLayout>
</template>
