<script setup lang="ts">
import type { BackgroundVideoProps } from './types';
import { ComboSettingLayout, ControlLayout, Toggle } from '@gem/control-v2';
import { computed } from 'vue';
import { useBackgroundVideo } from './hooks/useBackgroundVideo';
import { Select } from '@gem/control';
import { BG_VIDEO_TYPE } from './const';
import Input from '@gem/control/src/control/Input.vue';
import type { IconName } from '@gem/icons';

const props = defineProps<BackgroundVideoProps>();
const emit = defineEmits<{
  (e: 'controlChange', controlId: string, value?: BackgroundVideoProps['value']): void;
  (e: 'controlOnChange', controlId: string, value?: BackgroundVideoProps['value']): void;
}>();

const { videoUrlDisplay, handleChangeValue, handleClear, handleSetDefaultValue } = useBackgroundVideo(props, emit);

const comboIcon = computed((): IconName | undefined => {
  if (!videoUrlDisplay.value) return undefined;
  return props.value?.type === 'html5' ? 'polaris-play-circle' : 'polaris-logo-youtube';
});
</script>

<template>
  <ComboSettingLayout
    placeholder="Add..."
    :label="{ en: 'Background Video' }"
    :show-label="false"
    :content-display="videoUrlDisplay"
    :combo-icon="comboIcon"
    :is-full-width="true"
    @click="handleSetDefaultValue"
    @clear="handleClear">
    <div class="flex flex-col gap-16">
      <ControlLayout :label="{ en: 'Source' }" :label-variant="'secondary'">
        <template #control>
          <Select
            id="bgAttachment"
            :value="value?.type || 'youtube'"
            :options="BG_VIDEO_TYPE"
            @control-change="(_, value) => handleChangeValue('type', value)">
          </Select>
        </template>
      </ControlLayout>
      <ControlLayout :label="{ en: 'Video URL' }" :label-variant="'secondary'">
        <template #control>
          <Input
            v-if="value?.type === 'html5'"
            id="bgImageTitle"
            :value="value?.srcHtml5"
            placeholder="E.g: https://gempages.net"
            @control-change="(_, value) => handleChangeValue('srcHtml5', value)" />
          <Input
            v-else
            id="bgImageTitle"
            :value="value?.srcYoutube"
            placeholder="E.g: https://www.youtube.com/watch?v=cyzh48XRS4M"
            @control-change="(_, value) => handleChangeValue('srcYoutube', value)" />
        </template>
      </ControlLayout>
      <ControlLayout :label="{ en: 'Loop' }" :label-variant="'secondary'">
        <template #control>
          <Toggle :value="value?.loop || false" @control-change="(_, value) => handleChangeValue('loop', value)" />
        </template>
      </ControlLayout>
    </div>
  </ComboSettingLayout>
</template>
