import type { InjectionKey, Ref } from 'vue';
import type { GradientColor, GradientPoint } from '../types';

import { computed, nextTick, ref } from 'vue';
import { useDebounceFn } from '@vueuse/core';

import { Color } from '../Color';
import { toGradientObject, toGradientString } from '../helpers';
import { ID } from '@gem/common';

const useGradientColor = (
  gradientColor: Ref<GradientColor | undefined>,
  changeColor: (color: string, type?: 'change' | 'onChange') => void,
) => {
  const selectedPointID = ref<string>('');
  const selectedPoint = computed(() =>
    selectedPointID.value ? gradientColor.value?.points.get(selectedPointID.value) : undefined,
  );

  const debouncedUpdate = useDebounceFn((type?: 'change' | 'onChange') => {
    changeColor(toGradientString(gradientColor.value as GradientColor), type);
  }, 200);

  const addPoint = (gradientPoint: GradientPoint) => {
    const pointID = ID();
    gradientColor.value?.points.set(pointID, gradientPoint);
    nextTick(() => {
      if (gradientColor.value) {
        selectedPointID.value = pointID;
      }
    });
    debouncedUpdate();
  };

  const updateAngle = (angle: number) => {
    if (gradientColor.value) {
      gradientColor.value.angle = angle;
      debouncedUpdate();
    }
  };

  const updatePosition = (position: number) => {
    if (gradientColor.value) {
      gradientColor.value.points.set(selectedPointID.value, {
        ...(gradientColor.value.points.get(selectedPointID.value) as GradientPoint),
        position,
      });
      debouncedUpdate();
    }
  };

  const updateColor = (color: string, type?: 'change' | 'onChange') => {
    if (gradientColor.value) {
      gradientColor.value.points.set(selectedPointID.value, {
        ...(gradientColor.value.points.get(selectedPointID.value) as GradientPoint),
        color: Color.fromString(color),
      });
      debouncedUpdate(type);
    }
  };

  const removePoint = (pointID?: string) => {
    if (!gradientColor.value || gradientColor.value.points.size <= 2) return;
    gradientColor.value.points.delete(pointID || selectedPointID.value);
    changeColor(toGradientString(gradientColor.value as GradientColor));
    nextTick(() => {
      selectedPointID.value = [...gradientColor.value!.points.keys()][0];
    });
  };

  const selectPoint = (pointID: string) => {
    selectedPointID.value = pointID;
  };

  const setSelectedPointID = (pointID: string) => {
    selectedPointID.value = pointID;
  };

  const pickRecentColor = (color: string) => {
    changeColor(color);
    gradientColor.value = toGradientObject(color);
    if (gradientColor.value) {
      setSelectedPointID([...gradientColor.value.points.keys()][0]);
    }
  };

  return {
    selectedPointID,
    selectedPoint,
    addPoint,
    updateAngle,
    updateColor,
    updatePosition,
    removePoint,
    selectPoint,
    setSelectedPointID,
    pickRecentColor,
  };
};

export const GRADIENT_PROVIDE_KEY: InjectionKey<
  Omit<ReturnType<typeof useGradientColor>, 'selectedPoint' | 'setSelectedPointID' | 'pickRecentColor'>
> = Symbol('GRADIENT_CONTROL');

export default useGradientColor;
