import { useBorderColor } from './useBorderColor';
import { computed } from 'vue';
import type { BorderSettingProps, BorderStyle, BorderType } from '@gem/control-v2';
import { useBorderType } from './useBorderType';
import type { ColorSuggestions } from '@gem/common';

export const useBorderSettings = (borderSettingProps: BorderSettingProps, emit: any) => {
  const emptyValue: BorderStyle = {
    border: 'none',
  };
  const fixedValue: BorderStyle = {
    border: 'solid',
    color: '#212121',
    width: '1px',
    borderWidth: '1px',
  };

  const defaultValue = computed(() => {
    return borderSettingProps.compoDefaultValue || fixedValue;
  });
  const borderValues = computed(() => {
    return modifyInitValue(borderSettingProps.value);
  });

  const { modifyChangeColorBySettingID } = useBorderColor(borderSettingProps);
  const { typeOptions, borderTypeOptionSelected, getBorderStyleWhenChangeBorderType } = useBorderType(borderValues);

  const isCustom = (data?: BorderStyle) => {
    const borderValues = data?.width?.split(' ');
    return borderValues?.some((val) => val !== borderValues[0]);
  };

  const modifyInitValue = (value?: BorderStyle) => {
    value = {
      ...value,
      color: modifyChangeColorBySettingID(value?.color),
    };

    //Remap data if width value is in wrong format
    if (value?.width?.split(' ') && value?.width?.split(' ').length < 4) {
      const widthValue = value?.width?.split(' ')[0];
      value = {
        ...value,
        border: ['0px', '0', undefined].includes(widthValue) ? 'none' : 'solid',
        borderWidth: value.borderWidth ?? widthValue,
        width: `${widthValue} ${widthValue} ${widthValue} ${widthValue}`,
      } as BorderStyle;
    }

    //Remap borderWidth if width is custom but borderWidth is not set to Mixed yet
    if (isCustom(value) && value?.borderWidth !== 'Mixed') {
      value = { ...value, borderWidth: 'Mixed' } as BorderStyle;
    }
    //Remap for missing borderWidth
    if (value?.borderWidth === undefined && value?.width) {
      value = { ...value, borderWidth: value.width.split(' ')[0] } as BorderStyle;
    }
    return value;
  };

  const handleEmit = (data: BorderStyle, type: 'controlOnChange' | 'controlChange') => {
    if (type === 'controlChange') emit('controlChange', borderSettingProps.id, data);
    else emit('controlOnChange', borderSettingProps.id, data);
  };

  const handleUpdateSetting = (
    key: 'type' | 'color' | 'thickness',
    type: 'controlOnChange' | 'controlChange',
    value?: string,
  ) => {
    let data: BorderStyle = { ...borderValues.value, isCustom: true };
    switch (key) {
      case 'type':
        data = getBorderStyleWhenChangeBorderType(value as BorderType);
        break;
      case 'thickness':
        data = {
          ...data,
          width: value,
        };
        break;
      case 'color':
        data = {
          ...data,
          color: value,
        };
        break;
    }
    handleEmit(data, type);
  };

  const handleClearBorder = () => {
    handleEmit(emptyValue, 'controlChange');
  };

  const handleSetDefaultValue = () => {
    if (borderValues.value.border === 'none') handleEmit(defaultValue.value, 'controlChange');
  };

  const handleUpdateSuggestionColor = (value?: ColorSuggestions) => {
    emit('updateColorSuggestion', value);
  };

  return {
    borderValues,
    typeOptions,
    borderTypeOptionSelected,
    handleUpdateSetting,
    handleClearBorder,
    handleSetDefaultValue,
    handleUpdateSuggestionColor,
  };
};
