import { fontWeightNameMapping } from '@gem/common';

export const useGetFontWeightWithVariants = (variantsFont: string[]) => {
  return variantsFont
    .map((item) => {
      const value = ['italic', 'regular'].includes(item) ? '400' : item.replace('italic', '');
      return value;
    })
    .filter((c, index, arrs) => {
      return arrs.indexOf(c) === index;
    })
    .map((value) => {
      return {
        label: fontWeightNameMapping[value] ?? value,
        value: value,
      };
    });
};
