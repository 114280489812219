<script setup lang="ts">
import { ComboSettingLayout, ControlLayout } from '@gem/control-v2';
import { useToggle, useVirtualList } from '@vueuse/core';
import { flip, offset, shift } from '@floating-ui/core';
import { onMounted, ref, watch, nextTick } from 'vue';
import FontFamilyItem from './FontFamily/FontFamilyItem.vue';
import type { FontFamilyType } from '@gem/common';
import { useFontFamilySelect } from './hooks/useFontFamilySelect';
import type { FontFamilySelectProps } from './types';

const props = withDefaults(defineProps<FontFamilySelectProps>(), {
  middleware: () => [flip(), shift(), offset(4)],
});

const emit = defineEmits<{
  (e: 'controlChange', controlId: string, value?: any): void;
  (e: 'handleChangeFile', event: Event): void;
  (e: 'handleDeleteFont', closeModal: () => void, dataFontCurrent: any): void;
  (e: 'handleSaveFontMeta', value: any, type: string): void;
  (e: 'goToPricing'): void;
}>();

const keyword = ref('');
const {
  mixItems,
  currentOption,
  currentIndex,
  getLabelFont,
  isCheckedFontItem,
  loadGoogleFontByName,
  getStyleFontFamily,
  getVariantStyleFontFamily,
  onUploadFont,
  onHandleSaveFontMeta,
} = useFontFamilySelect(props, keyword, emit);

const inputSearchFont = ref<HTMLInputElement | null>();
const isShowModal = ref(false);
const dataFontCurrent = ref({});
const [isShow, toggle] = useToggle(false);

// ======== HOOK ========
const { list, containerProps, wrapperProps, scrollTo } = useVirtualList(mixItems, {
  itemHeight: 36,
  overscan: 10,
});

// ======== FUNCTION ========
const loadGoogleFonts = () => {
  const fonts = list.value;
  if (fonts?.length) {
    for (const font of fonts) {
      if (font?.data?.type !== 'google') continue;

      const fontFamily = font.data.value ?? '';
      loadGoogleFontByName(fontFamily);
    }
  }
};

// ======== ON HANDLER ========
const onClose = () => {
  toggle(false);
};
const onOpenModal = (e: Event) => {
  e.stopPropagation();
  isShowModal.value = true;
};
const onCloseModal = () => {
  isShowModal.value = false;
};
const onChange = (value: any, type: FontFamilyType) => {
  onHandleSaveFontMeta({
    value,
    type,
  });
  onClose();
  emit('controlChange', props.id, {
    value,
    type,
  });
};

// ======== WATCH ========
watch(isShow, () => {
  if (isShow) {
    nextTick(() => {
      inputSearchFont?.value?.focus();
    });
  }
});

watch(list, (newVal) => {
  if (newVal) {
    loadGoogleFonts();
  }
});

watch(
  () => keyword.value.trim().toLowerCase(),
  (newVal) => {
    if (newVal) {
      scrollTo(0);
    } else {
      scrollTo(currentIndex.value);
    }
  },
);

onMounted(() => {
  if (currentOption.value?.value && (currentOption.value?.type === 'google' || !currentOption.value?.type)) {
    loadGoogleFontByName(currentOption.value.value);
  }
});
</script>

<template>
  <ComboSettingLayout
    :id="id"
    placeholder="Add..."
    :is-parent="true"
    :label="{ en: 'Font picker' }"
    :show-label="false"
    :is-full-width="true"
    :is-hide-clear="true"
    :content-display="getLabelFont(currentOption?.value)"
    :input-style="{ 'font-family': `${getStyleFontFamily(currentOption?.value || '')}`, 'font-weight': '400' }"
    combo-icon="polaris-text-font">
    <ControlLayout layout="vertical">
      <template #control>
        <div class="bg-dark-300 mb-[12px] flex h-36 rounded-xl">
          <div class="flex aspect-square h-full items-center justify-center">
            <GBaseIcon name="search" width="16" class="shrink-0" />
          </div>
          <input
            ref="inputSearchFont"
            v-model="keyword"
            data-test="editor-control-font-input"
            placeholder="Search fonts"
            class="text-12 h-full flex-1 bg-transparent pr-8 outline-none" />
        </div>
        <div
          v-bind="containerProps"
          class="scrollbar:!w-[8px] scrollbar:bg-transparent scrollbar-track:!rounded-full scrollbar-track:!bg-dark-300 scrollbar-thumb:!rounded-full scrollbar-track:!cursor-grabbing scrollbar-thumb:!bg-light-450 h-[295px] overflow-x-hidden">
          <div v-bind="wrapperProps">
            <div class="bg-primary-300/10 mb-[12px] flex h-[44px] items-center gap-4 rounded-xl p-8">
              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15 10.5C15 11.0523 14.5523 11.5 14 11.5C13.4477 11.5 13 11.0523 13 10.5C13 9.94772 13.4477 9.5 14 9.5C14.5523 9.5 15 9.94772 15 10.5Z"
                  fill="#7190FF" />
                <path
                  d="M14.75 13.25C14.75 12.8358 14.4142 12.5 14 12.5C13.5858 12.5 13.25 12.8358 13.25 13.25V17.75C13.25 18.1642 13.5858 18.5 14 18.5C14.4142 18.5 14.75 18.1642 14.75 17.75V13.25Z"
                  fill="#7190FF" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14 21C17.866 21 21 17.866 21 14C21 10.134 17.866 7 14 7C10.134 7 7 10.134 7 14C7 17.866 10.134 21 14 21ZM14 19.5C17.0376 19.5 19.5 17.0376 19.5 14C19.5 10.9624 17.0376 8.5 14 8.5C10.9624 8.5 8.5 10.9624 8.5 14C8.5 17.0376 10.9624 19.5 14 19.5Z"
                  fill="#7190FF" />
              </svg>
              <p class="text-12 text-text-dark-500 font-normal">You should use a maximum of 3 fonts.</p>
            </div>

            <template v-for="item in list" :key="item.index">
              <p v-if="item.data?.isLabel" class="text-12 text-text-dark-300 mt-[16px] font-normal leading-5">
                {{ item.data?.label }}
              </p>
              <template v-else>
                <div
                  v-if="item.data.isRecently"
                  data-test="editor-control-font-option"
                  class="text-12 font-regular hover:bg-dark-300 group relative inset-0 flex h-36 cursor-pointer items-center rounded-xl bg-transparent transition-all before:absolute"
                  @click="onChange(item.data.value, item.data.type)">
                  <FontFamilyItem
                    :is-checked="isCheckedFontItem({ value: item.data?.value, type: item.data.type })"
                    :font-family="item.data.value"
                    :var-font-family="getVariantStyleFontFamily(item.data?.value, item.data.type)"
                    :label="getLabelFont(item.data?.value)" />
                </div>
                <div
                  v-else-if="item.data.type === 'custom'"
                  data-test="editor-control-font-option"
                  class="text-12 font-regular hover:bg-dark-300 group relative inset-0 flex h-36 cursor-pointer items-center rounded-xl bg-transparent transition-all before:absolute"
                  @click="onChange(item.data.value, 'custom')">
                  <FontFamilyItem
                    :is-checked="isCheckedFontItem({ value: item.data.value, type: 'custom' })"
                    :font-family="item.data.value"
                    :label="item.data.value"
                    :has-delete="!item.data?.isLoading"
                    :has-disabled-delete="
                      item.data.value === globalStyleFont?.heading.family ||
                      item.data.value === globalStyleFont?.body.family ||
                      item.data.value === value
                    "
                    :is-loading="item.data?.isLoading"
                    @open-modal-delete="
                      (e: any) => {
                        onOpenModal(e);
                        dataFontCurrent = item.data;
                      }
                    " />
                </div>
                <div
                  v-else-if="item.data.type === 'theme'"
                  data-test="editor-control-font-option"
                  class="text-12 font-regular hover:bg-dark-300 group relative inset-0 flex h-36 cursor-pointer items-center rounded-xl bg-transparent transition-all before:absolute"
                  @click="onChange(item.data.value, 'theme')">
                  <FontFamilyItem
                    :is-checked="isCheckedFontItem({ value: item.data.value, type: 'theme' })"
                    :font-family="item.data.value"
                    :var-font-family="getVariantStyleFontFamily(item.data?.value, item.data.type)"
                    :label="getLabelFont(item.data?.value)" />
                </div>
                <div
                  v-else-if="item.data.type === 'google'"
                  data-test="editor-control-font-option"
                  class="text-12 font-regular hover:bg-dark-300 group relative inset-0 flex h-36 cursor-pointer items-center rounded-xl bg-transparent transition-all before:absolute"
                  @click="onChange(item.data.value, 'google')">
                  <FontFamilyItem
                    :is-checked="isCheckedFontItem({ value: item.data.value, type: 'google' })"
                    :font-family="item.data.value"
                    :label="item.data.label" />
                </div>
              </template>
            </template>
          </div>
        </div>
        <div class="border-dark-300 mt-16 border-t pt-16">
          <GButtonV2
            type="tertiary"
            size="medium"
            class="text-12 relative w-full cursor-pointer items-center justify-center">
            <input
              id="fontFileInput"
              class="z-5 absolute left-[-84px] m-0 h-full w-[500px] cursor-pointer opacity-0 outline-none"
              type="file"
              multiple="true"
              accept=".ttf, .otf, .woff, .woff2"
              @change="onUploadFont" />
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.5 0.5C5.5 0.223858 5.27614 0 5 0C4.72386 0 4.5 0.223858 4.5 0.5V4.5H0.5C0.223858 4.5 0 4.72386 0 5C0 5.27614 0.223858 5.5 0.5 5.5H4.5V9.5C4.5 9.77614 4.72386 10 5 10C5.27614 10 5.5 9.77614 5.5 9.5V5.5H9.5C9.77614 5.5 10 5.27614 10 5C10 4.72386 9.77614 4.5 9.5 4.5H5.5V0.5Z"
                fill="#F9F9F9" />
            </svg>
            <div class="ml-8">
              <p class="text-12 text-text-dark-500 font-medium">Upload font</p>
            </div>
          </GButtonV2>
        </div>
        <Teleport to="body">
          <g-modal
            :show-btn-close="true"
            :is-open="isShowModal"
            btn-ok-type="danger"
            label-ok="Delete"
            @close="onCloseModal"
            @cancel="onCloseModal"
            @ok="$emit('handleDeleteFont', onCloseModal, dataFontCurrent)">
            <template #title>Delete font</template>
            <template #default>
              <div class="p-16">
                <p class="text-14 text-text-light-500 w-[568px] font-normal">
                  Are you sure you want to delete this uploaded font?
                </p>
                <ul class="list-disc pl-[20px]">
                  <li class="text-14 text-text-light-500 w-[568px] font-normal">
                    All heading using this font will be replaced by
                    <p class="inline font-semibold">{{ globalStyleFont?.heading.family }}</p>
                    - global heading font;
                  </li>
                  <li class="text-14 text-text-light-500 w-[568px] font-normal">
                    All paragraph using this font will be replaced by
                    <p class="inline font-semibold">{{ globalStyleFont?.body.family }}</p>
                    - global paragraph font.
                  </li>
                </ul>
              </div>
            </template>
          </g-modal>
        </Teleport>
      </template>
    </ControlLayout>
  </ComboSettingLayout>
</template>
