<script lang="ts" setup>
import type { OptionSelect } from '@gem/uikit';
import { GSelect } from '@gem/uikit';
import { computed, watch } from 'vue';
import { onMounted } from 'vue';
import { isDefined } from '@gem/common';

type PropsType = {
  id: string;
  value?: string | number | boolean;
  options?: {
    label?: string;
    value?: string;
    des?: string;
    metaData?: any;
    hideOnPage?: string[];
    icon?: string;
    svgIcon?: string;
    note?: string;
  }[];
  readonly?: boolean;
  disableDefault?: boolean;
  searchable?: boolean;
  searchTerm?: string;
  pageType?: string;
  dropdownClasses?: string;
  wrapperClass?: string;
  searchInputClass?: string;
  floating?: boolean;
  placeholder?: string;
  fixWidth?: boolean;
  triggerControlChange?: boolean;
  isFullWidth?: boolean;
};

const props = withDefaults(defineProps<PropsType>(), {
  floating: true,
  fixWidth: false,
});

const emit = defineEmits<{
  (e: 'controlChange', controlId: string, value?: any, screen?: any, options?: any): void;
}>();

const activeOption = computed(() => {
  const selected = props.options?.find(
    (v) => v.value === props.value && !v?.hideOnPage?.includes(props?.pageType ?? ''),
  );
  if (selected) return selected;

  if (props.disableDefault) return undefined;
  return gSelectOptions.value?.[0]?.value;
});

const gSelectOptions = computed<OptionSelect[]>(() => {
  return (
    props.options
      ?.map((option) => {
        return { id: option.value ?? '', value: option, name: option.label?.toString() ?? '' };
      })
      .filter(isDefined) ?? []
  );
});

const change = (_: any, selectedId: any, screen?: any, options?: any) => {
  emit('controlChange', props.id, selectedId, screen, options);
};

onMounted(() => {
  if (props.triggerControlChange)
    change(null, props.value, null, {
      noRecordHistory: true,
    });
});

watch(
  () => props.triggerControlChange,
  () =>
    change(null, props.value, null, {
      noRecordHistory: true,
    }),
);
</script>

<template>
  <div class="gemx-control">
    <slot name="label"></slot>
    <div class="gemx-control gemx-control-select">
      <GSelect
        data-test="editor-control-select"
        :dropdown-classes="dropdownClasses"
        :search-input-class="searchInputClass"
        :wrapper-class="wrapperClass"
        :options="gSelectOptions"
        :active-id="activeOption?.value"
        :searchable="searchable"
        :search-term="searchTerm"
        :floating="floating"
        :is-full-width="isFullWidth"
        :placeholder="placeholder"
        :fix-width="fixWidth"
        @select="change">
        <template #button-content>
          <div class="flex w-full items-center gap-8">
            <template v-if="activeOption?.svgIcon">
              <span v-html="activeOption?.svgIcon" />
            </template>
            <span class="text-12 inline-block truncate">
              {{ activeOption?.label }}{{ activeOption?.des ? ` ${activeOption?.des}` : '' }}
            </span>
          </div>
        </template>
        <template #content="slotProps">
          <slot name="content" :props="slotProps" />
        </template>
      </GSelect>
    </div>
    <slot name="info"></slot>
    <slot name="modal"></slot>
  </div>
</template>
