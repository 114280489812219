<script setup lang="ts">
import { GInput, GPopover } from '@gem/uikit-v2';
import parseUnit from '@gem/control/src/helpers/parseUnit';
import { computed, ref, watch } from 'vue';
import { GIcon } from '@gem/icons';
import type { SpacingGlobalType } from './types';
import { useGlobalStyleSpacing } from './hooks/useGlobalStyleSpacing';

const props = defineProps<{ id: string; value: string; options?: any; isFocus?: boolean }>();

const emit = defineEmits<{
  (e: 'controlChange', id: string, value?: string): void;
  (e: 'controlOnChange', id: string, value?: string): void;
  (e: 'onClickSubAction'): void;
}>();

const { spacing, getDisplayValue, getStringValueFromGlobal } = useGlobalStyleSpacing(props.options);

const spacingInput = ref<HTMLElement>();
const dropdownRef = ref();
const isShowDropDown = ref(false);

const val = ref(getStringValueFromGlobal(props.value)); // initial value

const displayValue = computed(() => getDisplayValue(val.value));

const parseValue = (value: string) => {
  const [textValue] = parseUnit(value);
  return textValue?.toString();
};

const handleOnChange = (value: string) => {
  if (!value) return;

  val.value = value;
  const valueParse = parseValue(value);
  const unit = parseUnit(value)[1];

  emit('controlOnChange', props.id, !isNaN(Number(valueParse)) ? (valueParse || '0') + (unit || 'px') : value);
};

const handleChange = (value: string) => {
  val.value = value;
  const valueParse = value ? parseValue(value) : value;

  const option = props.options.find(
    (item: any) =>
      item.id === value ||
      item.value?.replaceAll(' ', '') === (value as any)?.replaceAll(' ', '') ||
      item.value?.replaceAll(' ', '') === `${(value as any)?.replaceAll(' ', '')}px`,
  );
  if (!value) {
    emit('controlChange', props.id, '0');
  } else if (value === 'auto') {
    emit('controlChange', props.id, 'auto');
  } else if (option) {
    const globalValue = 'var(' + spacing.value[option.id as keyof SpacingGlobalType] + ')';
    emit('controlChange', props.id, globalValue);
  } else {
    const unit = parseUnit(value)[1];
    emit('controlChange', props.id, (valueParse || '0') + (unit || 'px'));
  }
};

const chooseSpacingGlobal = (value: string) => {
  if (value === 'auto') {
    emit('controlChange', props.id, 'auto');
  } else {
    const globalValue = 'var(' + spacing.value[value as keyof SpacingGlobalType] + ')';
    emit('controlChange', props.id, globalValue);
  }
};

const onMouseLeave = () => {
  !dropdownRef.value?.open && (isShowDropDown.value = false);
};

watch(
  () => props.value,
  (newVal) => {
    val.value = getStringValueFromGlobal(newVal);
  },
);

watch(
  () => props.isFocus,
  (newV) => {
    newV && spacingInput.value?.focus();
  },
);
</script>
<template>
  <div class="relative" @mouseover="() => (isShowDropDown = true)" @mouseleave="onMouseLeave">
    <GInput
      ref="spacingInput"
      align="center"
      type="number"
      :value="displayValue"
      @focus="() => (isShowDropDown = true)"
      @on-change="(value: string) => handleOnChange(value)"
      @change="(value: string) => handleChange(value)" />
    <div v-if="isShowDropDown" class="absolute top-8 right-0 cursor-pointer">
      <GPopover
        ref="dropdownRef"
        :has-arrow="false"
        :closeable="true"
        :overlay="false"
        :z-index="9999"
        overlay-container="#root-modal"
        placement="bottom-end"
        cls="!p-0"
        @close="() => (isShowDropDown = false)">
        <template #default="{}"
          ><div class="px-4"><GIcon :size="20" name="polaris-caret-down" /></div
        ></template>
        <template v-if="options?.length" #content="{ close }">
          <div class="rounded-12 bg-dark-400 shadow-4dp -mt-4 w-[180px] p-8">
            <template v-for="opt in options" :key="opt.id">
              <div
                class="disable-blur-when-click text-12 hover:bg-light-100/20 text-light-100 relative flex h-36 w-full cursor-pointer items-center truncate whitespace-nowrap rounded-xl pl-40 pr-8"
                @click="
                  () => {
                    chooseSpacingGlobal(opt.id);
                    close();
                    isShowDropDown = false;
                  }
                ">
                <span v-if="val === opt.id" class="text-light-450 absolute inset-y-0 left-8 flex items-center">
                  <GBaseIcon name="status-check" width="20" height="20" />
                </span>
                <p class="flex w-full items-center justify-between truncate">
                  <span class="truncate">{{ opt.name }}</span>
                  <span class="text-text-dark-300">{{ opt.value }}</span>
                </p>
              </div>
            </template>
            <p
              class="text-12 text-primary-200 border-light-500/[0.15] cursor-pointer border-t pt-8 pb-4 text-center font-medium leading-[18px]"
              @click.stop="() => emit('onClickSubAction')"
              @mousedown.stop="() => emit('onClickSubAction')">
              Edit global style
            </p>
          </div>
        </template>
      </GPopover>
    </div>
  </div>
</template>
