<script setup lang="ts">
import { watch } from 'vue';
import type { PaddingType } from '@gem/control-v2';
import { ComboSettingLayout, ControlLayout } from '@gem/control-v2';
import { GButton } from '@gem/uikit-v2';
import { computed, nextTick, ref } from 'vue';
import { useGlobalStyleSpacing } from '../base/hooks/useGlobalStyleSpacing';
import SpacingInput from '../base/SpacingInput.vue';

const props = defineProps<{ value: PaddingType; globalSpacingValues: any }>();

const emit = defineEmits<{
  (e: 'controlChange', value?: PaddingType): void;
  (e: 'controlOnChange', value?: PaddingType): void;
  (e: 'change-link', value: boolean): void;
  (e: 'edit-global-style'): void;
}>();

const { getDisplayValue, getStringValueFromGlobal } = useGlobalStyleSpacing(props.globalSpacingValues);

const defaultEnableValue = {
  top: '16px',
  right: '16px',
  bottom: '16px',
  left: '16px',
};

const isFocus = ref(false);
const val = computed(() => props.value);
const paddingTopVal = ref(getDisplayValue(val.value?.top));

const isZeroValue = computed(() => {
  return (
    (val.value?.top === '0px' || val.value?.top === '0' || val.value?.top === 'auto') &&
    val.value?.top === val.value?.right &&
    val.value?.right === val.value?.bottom &&
    val.value?.bottom === val.value?.left
  );
});

const isLinkedValue = computed(() => {
  if (props.value?.link) return props.value.link;
  return (
    props.value?.top === props.value?.right &&
    props.value?.right === props.value?.bottom &&
    props.value?.bottom === props.value?.left
  );
});

const isLinked = ref(isLinkedValue.value);

const displayComboValue = computed(() => {
  if (!val.value || !val.value.top || isZeroValue.value) return '';

  const output = [];
  for (const key in positionOptions) {
    const stringVal = getStringValueFromGlobal(val.value[key as keyof PaddingType] as string);
    const displayVal = getDisplayValue(stringVal);
    output.push(displayVal);
  }

  return output.join(', ');
});

const positionOptions = {
  top: {
    key: 'top',
    icon: 'border-position-top',
    iconPosition: 'first',
  },
  left: {
    key: 'left',
    icon: 'border-position-left',
    iconPosition: 'first',
  },
  right: {
    key: 'right',
    icon: 'border-position-right',
    iconPosition: 'last',
  },
  bottom: {
    key: 'bottom',
    icon: 'border-position-bottom',
    iconPosition: 'first',
  },
};

const handleEmit = (type: 'change' | 'onChange', key: string, value?: string) => {
  let dataChange = { ...val.value, [key]: value, link: isLinked.value };
  if (isLinked.value) {
    paddingTopVal.value = value as any;
    dataChange = { top: value, right: value, bottom: value, left: value, link: true };
  }
  if (type === 'onChange') {
    emit('controlOnChange', dataChange);
  } else emit('controlChange', dataChange);
};

const onChangeInput = (key: string, value?: string) => {
  handleEmit('onChange', key, value);
};

const changeInput = (key: string, value?: string) => {
  handleEmit('change', key, value ?? '0');
};

const handleOpenSetting = () => {
  (!val.value || !val.value.top || isZeroValue.value) && emit('controlChange', defaultEnableValue);
  isFocus.value = false;
  nextTick(() => {
    isFocus.value = true;
  });
};

const handleLinked = () => {
  isLinked.value = !isLinked.value;
  handleEmit('change', 'top', val.value['top']);
  emit('change-link', isLinked.value);
};

const handleClearCorner = () => {
  emit('controlChange', { top: '0', right: '0', bottom: '0', left: '0' });
};

watch(
  () => props.value,
  () => {
    paddingTopVal.value = getDisplayValue(props.value?.top);
    isLinked.value = isLinkedValue.value;
  },
);
</script>
<template>
  <ComboSettingLayout
    :id="id"
    placeholder="Add..."
    :is-parent="true"
    :label="{ en: 'Padding' }"
    :show-label="false"
    :is-full-width="true"
    :content-display="displayComboValue"
    :combo-icon="val?.top ? 'gp-padding' : ''"
    @click="handleOpenSetting"
    @clear="handleClearCorner">
    <ControlLayout layout="vertical">
      <template #control>
        <div class="flex w-full flex-col">
          <div class="relative grid w-full select-none grid-cols-2 gap-y-8">
            <div
              v-for="(item, key) in positionOptions"
              :key="key"
              :class="{ 'col-span-2': ['top', 'bottom'].includes(key) }">
              <div
                class="flex"
                :class="{
                  'justify-center': ['top', 'bottom'].includes(key),
                  'justify-end': key === 'right',
                }">
                <div class="w-[84px]">
                  <SpacingInput
                    :id="key"
                    ref="inputSearch"
                    :value="isLinked ? paddingTopVal : val?.[key]"
                    :options="globalSpacingValues"
                    :is-focus="isFocus && key === 'top'"
                    @on-click-sub-action="() => emit('edit-global-style')"
                    @control-on-change="(id: string, value: string) => onChangeInput(id, value)"
                    @control-change="(id: string, value: string) => changeInput(id, value)" />
                </div>
              </div>
            </div>
            <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
              <GButton
                type="ghost"
                :active="isLinked"
                size="small"
                :only-icon="isLinked ? 'gp-link' : 'gp-unlink'"
                @click="handleLinked" />
            </div>
          </div>
        </div>
      </template>
    </ControlLayout>
  </ComboSettingLayout>
</template>
