import { computed } from 'vue';
import type { CornerSettingProps, CornerRadius } from '../types';

export const useCornerSettings = (cornerSettingProps: CornerSettingProps, emit: any) => {
  const emptyValue: CornerRadius = { radiusType: 'none' };
  const pillValue: CornerRadius = {
    btrr: '9999px',
    bblr: '9999px',
    bbrr: '9999px',
    btlr: '9999px',
    radiusType: 'circle',
  };
  const fixedValue: CornerRadius = {
    btrr: '8px',
    bblr: '8px',
    bbrr: '8px',
    btlr: '8px',
    radiusType: 'rounded',
  };

  const defaultValue = computed(() => {
    return cornerSettingProps.compoDefaultValue || fixedValue;
  });
  type OptionItem = {
    title: string;
    value: string;
    keyword: 'circle' | 'small' | 'medium' | 'large' | 'extraLarge';
    valueDisplay?: string;
    showValue?: boolean;
  };
  const options: OptionItem[] = [
    {
      title: 'Circle',
      value: `9999px`,
      keyword: 'circle',
    },
    {
      title: 'Small',
      value: '4px',
      keyword: 'small',
      showValue: true,
    },
    {
      title: 'Medium',
      value: '8px',
      keyword: 'medium',
      showValue: true,
    },
    {
      title: 'Large',
      value: '16px',
      keyword: 'large',
      showValue: true,
    },
    {
      title: 'Extra large',
      value: '32px',
      keyword: 'extraLarge',
      showValue: true,
    },
  ];

  const cornerValues = computed(() => cornerSettingProps.value);

  const getValueByKey = (key: string) => {
    const valueByKey = cornerValues.value?.[key as keyof CornerRadius] as string;
    if (valueByKey) {
      return valueByKey.includes('%') ? valueByKey : convertValueToNumber(valueByKey);
    }
    return valueByKey;
  };

  const convertValueToNumber = (value: string) => {
    const num = parseInt(value);
    return isNaN(num) ? 0 : num;
  };

  const displayComboValue = computed(() => {
    if (!cornerValues.value || cornerValues.value?.radiusType === 'none') return '';

    return `${getValueByKey('btlr')}, ${getValueByKey('btrr')}, ${getValueByKey('bbrr')}, ${getValueByKey('bblr')}`;
  });

  const handleEmit = (data: CornerRadius, type: 'controlOnChange' | 'controlChange') => {
    if (type === 'controlChange') emit('controlChange', cornerSettingProps.id, data);
    else emit('controlOnChange', cornerSettingProps.id, data);
  };

  const handleUpdateSetting = (type: 'controlOnChange' | 'controlChange', value?: CornerRadius) => {
    if (!value) return;

    let data: CornerRadius = value;
    if (value.radiusType === 'circle') data = pillValue;
    handleEmit(data, type);
  };
  const handleSetDefaultValue = () => {
    if (cornerValues.value?.radiusType === 'none') handleEmit(defaultValue.value, 'controlChange');
  };
  const handleClearCorner = () => {
    handleEmit(emptyValue, 'controlChange');
  };
  return {
    cornerValues,
    displayComboValue,
    options,
    handleUpdateSetting,
    handleSetDefaultValue,
    handleClearCorner,
    getValueByKey,
  };
};
