<script setup lang="ts">
import { computed, onMounted, provide, ref } from 'vue';
import {
  generateNewGlobalStyleColor,
  makeGradientColorFormColor,
  toGradientObject,
  TRANSPARENT_COLOR,
} from '../helpers';
import type { ColorPickerProps, GradientColor } from '../types';
import useGradientColor, { GRADIENT_PROVIDE_KEY } from '../composables/useGradientColor';
import { type ColorSuggestions } from '@gem/common';
import useSettingSideBarStore from '../../../hooks/useSettingSideBarStore';
import InputColor from './InputColor.vue';
import ColorList from './ColorList.vue';
import ColorOnly from './ColorOnly.vue';
import GradientPicker from './gradient-picker/GradientPicker.vue';
import { START_CHANGE_INDEX_OF_GRADIENT_TAB, MAX_COLOR_SIZE } from '../hooks/useMyColor';

const props = defineProps<ColorPickerProps>();

const emits = defineEmits<{
  (e: 'controlChange', controlId: string, value?: string): void;
  (e: 'controlOnChange', controlId: string, value?: string): void;
  (e: 'saveMyColors', value?: ColorSuggestions): void;
}>();

const settingSideBarStore = useSettingSideBarStore();

const getGradientColor = computed(() => {
  const gradientColor = makeGradientColorFormColor(props.value);
  return makeGradientColorFormColor(gradientColor);
});

const gradientColorObject = ref<GradientColor | undefined>(toGradientObject(getGradientColor.value));
const shouldReRenderDueToInputChange = ref(false);
const currentGlobalStyleColor = computed(() => settingSideBarStore.globalStyles?.color || {});

const recentGradientColors = computed(() =>
  currentGlobalStyleColor.value && currentGlobalStyleColor.value['recent-gradient-colors']
    ? currentGlobalStyleColor.value['recent-gradient-colors']
    : [],
);

const getMyColorChange = (newColor: string | undefined) => {
  if (!newColor) return;

  const changeIndex = currentGlobalStyleColor?.value['gradient-index-change'] || 5;
  const changeInfo = {
    colorKeys: ['recent-gradient-colors'],
    changeIndexKey: 'gradient-index-change',
    initStartIndex: START_CHANGE_INDEX_OF_GRADIENT_TAB,
    changeIndex,
  };
  return generateNewGlobalStyleColor(newColor, recentGradientColors.value, MAX_COLOR_SIZE, changeInfo);
};

const changeColor = (color: string, type?: 'change' | 'onChange') => {
  const newColor = (color || TRANSPARENT_COLOR).toLowerCase();
  if (type === 'onChange') emits('controlOnChange', props.id, newColor);
  else {
    if (!recentGradientColors.value.includes(newColor)) {
      emits('saveMyColors', getMyColorChange(newColor));
    }
    emits('controlChange', props.id, newColor);
  }
};

const {
  selectedPointID,
  selectedPoint,
  addPoint,
  updateAngle,
  updateColor,
  updatePosition,
  removePoint,
  selectPoint,
  setSelectedPointID,
  pickRecentColor,
} = useGradientColor(gradientColorObject, changeColor);

provide(GRADIENT_PROVIDE_KEY, {
  selectedPointID,
  addPoint,
  updateAngle,
  updateColor,
  updatePosition,
  removePoint,
  selectPoint,
});

const showTransparentColor = computed(() => !props.value || props.value === TRANSPARENT_COLOR);

const handleInputChange = (value: string) => {
  updateColor(value);
  shouldReRenderDueToInputChange.value = !shouldReRenderDueToInputChange.value;
};

const handleInputOnChange = (value: string) => {
  updateColor(value, 'onChange');
  shouldReRenderDueToInputChange.value = !shouldReRenderDueToInputChange.value;
};

onMounted(() => {
  if (!selectedPointID.value && gradientColorObject.value) {
    setSelectedPointID([...gradientColorObject.value.points.keys()][0]);
  }
});
</script>

<template>
  <div class="flex flex-col gap-16">
    <GradientPicker :gradient-color="gradientColorObject" @on-remove-point="removePoint" />
    <InputColor
      :value="selectedPoint?.color.getColor()"
      :show-transparent-color="showTransparentColor"
      @on-change="handleInputOnChange"
      @change="handleInputChange" />
    <ColorOnly
      :key="`${selectedPointID}_${shouldReRenderDueToInputChange.toString()}`"
      :value="selectedPoint?.color.getColor()"
      :is-auto-update-value="false"
      @change-color="(value) => updateColor(value)" />
    <ColorList
      v-if="recentGradientColors && recentGradientColors.length > 0"
      title="Recent colors"
      :colors="recentGradientColors"
      :color="value"
      @on-pick-color="pickRecentColor" />
  </div>
</template>
