<script setup lang="ts">
import { InputNumber, type BorderStyle } from '@gem/control-v2';
import { useBorderWidth } from './hooks/useBorderWidth';
import { computed } from 'vue';
import { GButton } from '@gem/uikit-v2';

type Props = {
  id: string;
  value?: BorderStyle;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'controlChange', value?: string): void;
  (e: 'controlOnChange', value?: string): void;
}>();

const borderValues = computed(() => props.value);
const {
  positionOptions,
  isLink,
  getPositionWidth,
  changeActiveKey,
  handleOnChangeWidth,
  handleInputBlur,
  handleClickLinkAction,
} = useBorderWidth(borderValues, emit);
</script>

<template>
  <div class="-mt-8 flex w-full flex-col">
    <div class="relative grid w-full select-none grid-cols-2 gap-y-8">
      <div v-for="(item, key) in positionOptions" :key="key" :class="{ 'col-span-2': ['top', 'bottom'].includes(key) }">
        <div
          class="flex"
          :class="{
            'justify-center': ['top', 'bottom'].includes(key),
            'justify-end': key === 'right',
          }">
          <div class="w-[84px]">
            <InputNumber
              :id="id"
              :min="0"
              :value="getPositionWidth(key)"
              align="center"
              @control-change="handleInputBlur"
              @control-on-change="handleOnChangeWidth"
              @focus="changeActiveKey(key)" />
          </div>
        </div>
      </div>
      <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <GButton
          type="ghost"
          :active="isLink"
          size="small"
          :only-icon="isLink ? 'gp-link' : 'gp-unlink'"
          @click="handleClickLinkAction" />
      </div>
    </div>
  </div>
</template>
